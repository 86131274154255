const syllabus = [
    {name: 'Physics' , info: 'Fundamentals of ultrasound imaging, Doppler, and color flow principles.'},
    {name: 'Doppler Effect' , info: 'Explanation and applications for different types of Doppler.'},
    {name: 'Instrumentation' , info: 'Overview of measuring instruments used for indication, measuring, and recording physical quantities'},
    {name: 'Sonographic Anatomy' , info: 'Study of anatomy visualized through ultrasound.'},
    {name: 'Artifacts' , info: 'Explanation of incorrect reproduction of internal structure in ultrasound images.'},
    {name: 'Knobology' , info: 'Overview of machine control functions relevant to their applications.'},
    {name: 'Pathologies' , info: 'Study of different types of pathologies.'},
    {name: 'Ultrasound Protocols' , info: 'Guidelines for use of ultrasound in different clinical scenarios.'},
    {name: 'Documents and Reporting' , info: 'Best practices for documenting and reporting ultrasound findings.'},
    {name: 'Hands-On Training' , info: 'Practical, hands-on training in use of ultrasound technology.'},
    {name: 'Clinical Placement' , info: 'Practice on different types of pathologies with 10-12 patients seen every day.'},
];
const courses = [{
    id: 1,
    name: 'Diploma in Ultrasonology',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'Diploma in sonography with theory and practical courses covering the fundamentals of ultrasound physics, instrumentation, anatomy, pathology, and hands-on training with patients. Provides internationally recognized certification and career advancement opportunities. Also offers online classes.',
    outline: ['assets/img/1.jpeg','assets/img/2.jpeg','assets/img/3.jpeg','assets/img/4.jpeg']
},
{
    id: 2,
    name: 'Certificate in Ultrasonology',
    period: '6 months',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This is a certification program that trains individuals in the use of ultrasonography, a diagnostic imaging technique that uses high-frequency sound waves to create images of internal body structures. This certificate program may cover various areas of ultrasound imaging such as abdominal, vascular, gynecological, and obstetric.'},
{
    id: 3,
    name: 'OB/GYN& Breast',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course focuses on the use of ultrasound in the imaging of female reproductive organs, including the uterus, ovaries, and breasts. It covers the use of ultrasound for detecting and diagnosing various conditions in these areas.'
},
    {
    id: 4,
    name: 'Vascular Doppler',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course covers the use of Doppler ultrasound technology for imaging blood vessels and detecting blood flow abnormalities. It is commonly used for the diagnosis of peripheral vascular disease, deep vein thrombosis, and arterial stenosis.'
    },
{
    id: 5,
    name: 'Primary Care',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course covers the use of ultrasound in primary care settings, such as family medicine or general practice. It may cover a range of ultrasound applications, including musculoskeletal, cardiac, and abdominal imaging.',
    },
{
    id: 6,
    name: 'Point of care',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: `Point of care ultrasound refers to the use of ultrasound technology at the patient's bedside or in a clinical setting. This course teaches practitioners how to use portable ultrasound machines to diagnose and treat various conditions, such as fluid buildup in the lungs, abdominal pain, and trauma injuries.`,
    },
{
    id: 7,
    name: 'ABDOMINAL OBST & GYNAE',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course focuses specifically on the use of ultrasound in the imaging of the abdominal and pelvic regions, including the organs of the digestive system and the female reproductive system.',
    },
{
    id: 8,
    name: 'EMERGENCY CARE',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course covers the use of ultrasound in emergency medicine, such as in the diagnosis of trauma injuries, detecting internal bleeding, and identifying the presence of foreign objects.',
    },
{
    id: 9,
    name: 'REGISTRY Review',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course is designed to prepare individuals for the registry examination in ultrasound imaging. The registry is a professional certification offered by various organizations, and passing it demonstrates a high level of expertise in the field.',
    },
{
    id: 10,
    name: 'Small Parts',
    period: '1 year',
    time: "12pm to 2pm  and 3pm to 5pm Monday to Wednesday &  Hand on training Thursday and Friday 3pm to 5pm",
    price: 'For Prices Call: +92-3042348697(Whatsapp) or +(021)-34326600',
    info: 'This course covers the use of ultrasound in the imaging of small structures, such as the thyroid gland, breast nodules, and testicles. It may also cover the use of ultrasound for guiding biopsies and other procedures in these areas.',
    },

];

const Teachers = [
{
    name: 'Dr.Ghazala Iqbal',
    img : '',
    degree: 'MBBS,RDMS,RVT(USA)',
    gender: 'female',
    designation: '',
    exp: ''
},
{
    name: 'Dr.Syed Tahir Rasool',
    img : '',
    degree: 'MBBS (Sonologist)',
    gender: 'male',
    designation: '',
    exp: ''
},
{
    name: 'Dr.Farida',
    img : '',
    degree: 'MBBS (Sonologist)',
    gender: 'female',
    designation: '',
    exp: ''
},
{
    name: 'Dr Shamim Akhter',
    img : '',
    degree: 'MBBS(Sonologist) , FCPS(Radiologist)',
    gender: 'female',
    designation: '',
    exp: ''
}
];

const male = 'assets/img/male-doc.png';
const female = 'assets/img/female-avatar.png';
const address = 'Shahrah-e-Faisal Service Rd S, Pakistan Employees Co-Operative Housing Society Block 6 PECHS, Karachi, Karachi City, Sindh';
const phone = '+(021)-34326600';
const whatsapp = '+92-3042348697(Whatsapp)'
const email = 'arshadhaq77@yahoo.com / ioslultra@gmail.com';
const facebook_link = 'https://www.facebook.com/ioslkarachi/';
const g_map = 'https://www.google.com/maps?ll=24.860325,67.064372&z=13&t=m&hl=en&gl=US&mapclient=embed&cid=8776677407877729375'


export {courses , address , phone , email, Teachers , whatsapp, facebook_link , g_map, male , female , syllabus};
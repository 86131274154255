import React from 'react'

const Loader = () => {
  return (
    <div className="lds-circle">
        <div></div>
    </div>
  )
}

export default Loader